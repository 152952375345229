import React from 'react';
import STARTER from '../assets/image/starter.png';
import TWITTER from '../assets/image/twittercomponent.jpeg';
import TODO from '../assets/image/todo.png';
import HIERARCHY from '../assets/image/hierarchy.png';
import LIFECYCLE from '../assets/image/lifecycle.png';
import REDUX from '../assets/image/redux.gif'
import AREDUX from '../assets/image/aredux.gif'

const images = {
    "starter": STARTER,
    "twitter": TWITTER,
    "todo": TODO,
    "hierarchy": HIERARCHY,
    "lifecycle": LIFECYCLE,
    "redux": REDUX,
    "aredux": AREDUX
}

const style = {
    width: '100%',
    height: 'auto'
}

const Image = ({ filename }) => (
    <img style={style} src={images[filename]} alt={filename} />
)

export default Image;
