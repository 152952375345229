import DeckTitle from "components/deckTitle";
import Image from 'components/Image';
import Image2 from 'components/Image';
import DeckTitle2 from "components/deckTitle";
import React from 'react';
export default {
  DeckTitle,
  Image,
  Image2,
  DeckTitle2,
  React
};