import DeckTitle from "components/deckTitle";
import DeckTitle2 from "components/deckTitle";
import Image from "components/Image";
import Image2 from "components/Image";
import Image4 from "components/Image";
import Image3 from "components/Image";
import Image5 from "components/Image";
import DeckTitle3 from "components/deckTitle";
import React from 'react';
export default {
  DeckTitle,
  DeckTitle2,
  Image,
  Image2,
  Image4,
  Image3,
  Image5,
  DeckTitle3,
  React
};